import React from "react"
import "./BackToTopSticky.scss"

const BackToTopSticky = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  return (
    <div className="back-top-section" onClick={() => handleClick()}>
      <i className="icon icon-backto-top"></i>
      <p>Back To Top</p>
    </div>
  )
}

export default BackToTopSticky
