import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import "./Footer.scss"
import sbLogo from "../../images/icons/sb-logo.svg"
import { Container } from "react-bootstrap"
import { GetPageURL, phoneNumber } from "../common/site/utils"
import { useMedia } from "react-use";
import Helmet from 'react-helmet'
import CookieConsent from "react-cookie-consent";
import $ from "jquery";
import BackToTopSticky from "../BackToTopSticky/BackToTopSticky"

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query GetFooterData {
      glstrapi {
        socialLink {
          footer_menu {
            id
            title
            footer_links {
              custom_link
              id
              label
              link_type
              target
              menu_item {
                label
                link_type
                slug
                parent {
                  slug
                  label
                  parent {
                    slug
                    label
                    parent {
                      slug
                      label
                    }
                  }
                }
                id
              }
            }
          }
        }
        siteConfig {
          twitter_link
          linkedin_link
          instagram_link
          Footer_Logo {
            url
          }
        }
      }
    }
  `)

  const isMobile = useMedia("(max-width: 768px)", false)
  const isTablet = useMedia("(min-width: 1024px)")
  
  const isOffplan = props.layout === 'off-plan'
  const [scroll, setScroll] = useState(false)

  const scrollTop = () => {
    if (typeof window != 'undefined') {
        window.scrollTo(0,0)
    }
}
  const footerMenuData = data.glstrapi.socialLink.footer_menu
  const footerSocialData = data.glstrapi.siteConfig

  const currentYear = new Date().getFullYear()

    // Cookie Trigger
    useEffect(() => {
      setTimeout(function(){
        if ($('.cookie-popup .CookieConsent').length > 0) { 
          $('.footer-wrapper').addClass('with_cookie-popup');
        } 
        $('.cookieok').click(function(event) {
          $('.acceptcookie').click();
        }); 
      }, 500);

      const windowScroll = () => {
        if (window.pageYOffset > 850) {
          setScroll(true)
        } else {
          setScroll(false)
        }
      }
      window.addEventListener("scroll", windowScroll);
      return () => window.removeEventListener("scroll", windowScroll);
    }, [])
  //  
  var showbugherd = process.env.GATSBY_SHOW_BUGHERD

  // 
  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  const trackerEvent = (event) => {    
   window.dataLayer = window.dataLayer || [];
   window.dataLayer.push({
       'event': event,
       'formType': event + ' - ' +shareurl,
       'formId': event,
       'formName': event,
       'formLabel': event
   });    
   }

  // 
  return (
    <>
    {showbugherd == "true" ?
      <Helmet>
        <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=lspwhsj2boxqmusejwe4fq" async="true"></script>
      </Helmet>
      : "" }
    <div className="footer-wrapper section-p">
      <Container className="footer-container">
        <div className="footer-top">
          <div className="valuation-section">
            <p className="heading">book a valuation</p>
            <p className="title">List your property</p>
            <Link to={"/property-valuation-in-dubai/"} className="btn btn-white">
              Find out more
            </Link>
          </div>
          <div className="menus-wrap">
            {footerMenuData.length > 0 &&
              footerMenuData.map(menu => (
                <div className="menu-list-wrap">
                  <p className="title">{menu.title}</p>
                  <div className="menu-list">
                    {menu.footer_links?.length > 0 &&
                      menu.footer_links.map(link => (
                        <Link
                          target={link?.target === "new_window" ? "_blank" : ""}
                          to={GetPageURL(link.menu_item, link.custom_link)}
                          className="list-item"
                          onClick={() => scrollTop()}
                        >
                          {link.label}
                        </Link>
                      ))}
                  </div>
                </div>
              ))}
            {/* <div className="menu-list-wrap">
              <p className="title">office address</p>
              <div className="menu-list">
                <Link
                  to="/contact-omhome/"
                  className="list-item address-item"
                >
                  OM Home
                  <br />
                  PO Box 123234,
                  <br />
                  Downtown Dubai,
                  <br />
                  Dubai
                </Link>
              </div>
            </div> */}
            <div className="menu-list-wrap">
              <p className="title">general enquiries</p>
              <div className="menu-list">
                <Link to={"/contact-omhome/"} className="list-item">
                  hello@omhome.ae
                </Link>
                <a href={`tel:${phoneNumber}`} className="list-item" onClick={()=>trackerEvent("Phone number click")}>
                  {phoneNumber}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="Fdivider"></div>
        <div className="footer-end">
          <div className="terms-section">
            <p className="copyright">© {currentYear} om home</p>
            <div className="links">
              <Link to="/privacy-policy/">Privacy</Link>
              {isMobile && " / "}
              <Link to="/cookie-policy/">Cookie Policy</Link>
              {isMobile && " / "}
              <Link to="/terms-and-conditions/">Terms and Conditions</Link>
            </div>
          </div>
          <div className="site-by">
            Site by:{" "}
            <a href="https://starberry.tv/" target='_blank'>
              <img src={sbLogo} alt="Starberry Logo" />
            </a>
          </div>
        </div>
        {(isOffplan && scroll && isTablet) &&
          <section className="position-relative">
            <BackToTopSticky />
          </section>
        }
      </Container>
      <section className="cookie-popup">
        <CookieConsent
              location="bottom"
              buttonText="Accept Cookies"
              cookieName="CookiePolicy"
              buttonClasses="acceptcookie"
              overlay={false}
              // acceptOnScrollPercentage={1}
              //onAccept={()=> ClosePopup()}
          >
              <div className="topbar-block">

                  <p>
                  We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to='/cookie-policy/'>Cookie Policy</Link>. <a href="javascript:void(0);" class="cookieok">Accept Cookies</a>
                  </p>
                  <i className="icon-close-dark"></i>
              </div>
          </CookieConsent>
      </section>

    </div>
    </>
  )
}

export default Footer
